@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #070707;
}
.container {
  @apply mx-auto px-10 md:px-6 max-w-7xl;
}

@keyframes slideFadeIn {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-fade-in {
  animation: slideFadeIn 1s ease-out;
}
